import '../shared/App.css';
import React from 'react';

function PlayerPortal() {
  return (
    <div className="App-page">
      <h1>Player Portal</h1>
      <p> Nothing to see here</p>
    </div>
  );
}

export default PlayerPortal;
