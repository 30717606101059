import '../shared/App.css';
import React from 'react';

function TeamPortal() {
  return (
    <div className="App-page">
      <h1>Team Portal</h1>
      <p> Nothing to see here</p>
    </div>
  );
}

export default TeamPortal;
