import '../shared/App.css'


function LoadingPage() {
  return (
    <div>
        <h1>Loading...</h1>
    </div>
  );
}

export default LoadingPage;