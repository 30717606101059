import '../shared/App.css'


function ErrorPage() {
  return (
    <div>
        <h1>Error Data Unavailable</h1>
    </div>
  );
}

export default ErrorPage;